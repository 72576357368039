import styled from "styled-components";
import { BalanceCardContainer } from "../balanceCard/BalanceCard.styles";
import { ContractAddressContainer } from "../contractAddress/ContractInformation.styles";

export const DashboardContainer = styled.div`
    width: 100%;
    margin-top: 24px;
`;

export const HorizontalSection = styled.div`
    display: block;
    grid-gap: 24px;

    @media (min-width: 800px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 1024px;
        margin: auto;
    }
`;

export const DashboardSection = styled.div`
    background-color: #242424d1;
    box-sizing: border-box;
    margin: auto;
    width: 100%;
    padding: 12px;
    margin-bottom: 24px;

    @media (min-width: 800px) {
        max-width: 1024px;
        padding: 24px;
    }
`;

export const TotalDistributed = styled.div`
    display: flex;
    text-align: center;
    align-items: stretch;
    flex-direction: column;

    span {
        display: inline-block;
        margin-top: 24px;
        font-size: 1.5rem;
        background-color: ${(props) => props.theme.background.highlightLight};
        padding: 24px;
    }
`;

export const SectionHeader = styled.h2``;

export const SectionContent = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 800px) {
        flex-direction: row;
        justify-content: space-between;

        ${BalanceCardContainer}:not(:last-of-type), ${ContractAddressContainer}:not(:last-of-type) {
            margin-right: 24px;
        }
    }
`;

export const Notice = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;
