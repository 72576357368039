import {
    BalanceCardContainer,
    BalanceCardFooter,
    BalanceNumber,
    BalanceSymbol,
    CurrencyLogo,
} from "./BalanceCard.styles";

interface Props {
    balance: string;
    symbol: string;
    logoSrc?: string;
}

export const BalanceCard: React.FC<Props> = ({ balance, symbol, logoSrc }) => {
    const balanceText = balance === "-" ? "0.000" : (+balance).toFixed(3);
    return (
        <BalanceCardContainer>
            <BalanceNumber>{balanceText}</BalanceNumber>

            <BalanceCardFooter>
                {logoSrc && <CurrencyLogo src={logoSrc} alt="Currency Logo" />}

                <BalanceSymbol>{symbol}</BalanceSymbol>
            </BalanceCardFooter>
        </BalanceCardContainer>
    );
};
