import { 
    ContractAddressContainer,
    ContractName ,
    AddressFooter, 
    ContractAddress, 
    CopyAddressButton, CopyAddressImage } from "./ContractInformation.styles";
import copyIcon from "../../images/copy-32.png";

interface Props {
  contractName: string;
  contractAddress: string;
}

export const ContractInformation: React.FC<Props> = ({ contractName, contractAddress }) => {
  return (
    <ContractAddressContainer>
      <ContractName>{contractName} Contract</ContractName>
      <AddressFooter>
        <ContractAddress>{contractAddress}</ContractAddress>
        <CopyAddressButton onClick={() => navigator.clipboard.writeText(contractAddress) }>
          <CopyAddressImage src={copyIcon} alt="Copy Icon"/>
        </CopyAddressButton>
      </AddressFooter>
    </ContractAddressContainer>
  );
};
