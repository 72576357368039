export const darkTheme = {
    background: {
        main: "#121212",
        highlight: "#242424",
        highlightLight: "#333333",
    },
    form: {
        primary: "#3773f5",
    },
};

export type DashboardTheme = typeof darkTheme;
