import styled from "styled-components";

export const HeaderLogo = styled.img`
    height: 36px;
`;

export const WebRewardLogo = styled.img`
    height: 3rem;
    width: 3rem;

    @media (min-width: 800px) {
        margin-left: 3rem;
    }
`;

export const HeaderContainer = styled.div`
    background-color: ${(props) => props.theme.background.highlight};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    color: white;
    font-size: 1.5rem;
    ${HeaderLogo} {
        margin-right: 12px;
    }

    a:hover, a:visited, a:link {
        text-decoration: none;
        color: white;
    }
`;

export const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
`;


export const WebRewardText = styled.div`
    margin-left: 0.5rem;
    @media (max-width: 800px) {
        font-size: 1rem
    }
`;


export const HeaderContents = styled.div`
    display: flex;
    align-items: center;
`;
