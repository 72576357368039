import { createGlobalStyle } from "styled-components";
import { DashboardTheme } from "./themes";

export const GlobalStyles = createGlobalStyle<{ theme: DashboardTheme }>`
	body {
		padding: 0;
		margin: 0;
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		background: ${(props) => props.theme.background.main};
		color: white;
		height: 100vh;
	}

	.root {
		height: 100%;
	}

	h1 {
		margin: 0;
	}

	h2 {
		margin: 0;
	}
`;
