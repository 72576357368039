import { ThemeProvider } from "styled-components";
import { AppContainer, SectionContainer } from "./App.styles";
import { Dashboard, Header } from "./components";
import { DashboardSection, Notice} from "./components/dashboard/Dashboard.styles";
import { MetamaskStore } from "./stores/metamask";
import { GlobalStyles } from "./styles/global";
import { darkTheme } from "./styles/themes";

function App() {
    // @ts-ignore
    const isWeb3 = typeof web3 !== 'undefined';
    if (!isWeb3) {
        return (
            <ThemeProvider theme={darkTheme}>
            <GlobalStyles />
            <Header isWeb3={isWeb3} />
            <SectionContainer>
                <DashboardSection>
                    <Notice>
                        <h3>
                            Your browser does not seem to support Web3 applications. Please install Metamask (or equivalent) browser extension.
                        </h3>
                    </Notice>
                </DashboardSection>
            </SectionContainer>
            </ThemeProvider>
        )
    }
    return (
        <MetamaskStore>
            <ThemeProvider theme={darkTheme}>
                <GlobalStyles />
                <Header isWeb3={isWeb3}/>
                <AppContainer>
                    {/* <Sidebar /> */}
                    <Dashboard />
                </AppContainer>
            </ThemeProvider>
        </MetamaskStore>
    );
}

export default App;
