import styled from "styled-components";
import backgroundUrl from "./images/background-small.jpeg";

export const AppContainer = styled.div`
    height: calc(100vh - 66px);
    display: flex;
    background-image: url(${backgroundUrl});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: scroll;
`;

export const Notice2 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const SectionContainer = styled.div`
    margin-top: 24px;
`;
