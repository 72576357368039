import { useMetamask } from "../../hooks/useMetamask";
import { useWallet } from "../../hooks/useWallet";
import { BalanceCard } from "../balanceCard/BalanceCard";
import {
    DashboardContainer,
    DashboardSection,
    HorizontalSection,
    Notice,
    SectionContent,
    TotalDistributed,
} from "./Dashboard.styles";
import bnbLogo from "../../images/bnb_32.png";
import busdLogo from "../../images/busd_32.png";
import { ContractInformation } from "../contractAddress/ContractInformation";

export const Dashboard: React.FC = () => {
    const { walletAddresses } = useMetamask();
    const {
        bnb,
        webReward,
        busd,
        unpaidEarnings,
        totalEarnings,
        totalDistributed,
    } = useWallet(walletAddresses[0] ?? "");
    return (
        <DashboardContainer>
            <DashboardSection>
                <Notice>
                    <h3>
                        (Please make sure you are visiting
                        https://dashboard.webreward.net)
                    </h3>
                </Notice>
            </DashboardSection>
            <DashboardSection>
                <h2>My Portfolio</h2>
                <SectionContent>
                    <BalanceCard balance={webReward} symbol="WRC" />
                    <BalanceCard balance={bnb} symbol="BNB" logoSrc={bnbLogo} />
                    <BalanceCard
                        balance={busd}
                        symbol="BUSD"
                        logoSrc={busdLogo}
                    />
                </SectionContent>
            </DashboardSection>

            <HorizontalSection>
                <DashboardSection>
                    <h2>Unpaid Earnings</h2>
                    <SectionContent>
                        <BalanceCard
                            balance={unpaidEarnings}
                            symbol="BUSD"
                            logoSrc={busdLogo}
                        />
                    </SectionContent>
                </DashboardSection>

                <DashboardSection>
                    <h2>Total Earnings</h2>
                    <SectionContent>
                        <BalanceCard
                            balance={totalEarnings}
                            symbol="BUSD"
                            logoSrc={busdLogo}
                        />
                    </SectionContent>
                </DashboardSection>
            </HorizontalSection>

            <DashboardSection>
                <TotalDistributed>
                    <h2>Total Distributed</h2>
                    <span>
                        {totalDistributed === "-"
                            ? "0.000"
                            : (+totalDistributed).toFixed(3)}
                    </span>
                </TotalDistributed>
            </DashboardSection>

            <DashboardSection>
                <SectionContent>
                    <ContractInformation
                        contractName={"WebReward"}
                        contractAddress={
                            "0x2a2dA21ca96202387a80a1DD284829a1A9f5A678"
                        }
                    ></ContractInformation>
                    <ContractInformation
                        contractName={"BUSD"}
                        contractAddress={
                            "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                        }
                    ></ContractInformation>
                </SectionContent>
            </DashboardSection>
        </DashboardContainer>
    );
};
