import { ethers } from "ethers";
import React, { useEffect, useRef, useState } from "react";

interface MetamaskState {
    isConnected: boolean;
    connect: () => void;
    walletAddresses: string[];
}

const defaultState: MetamaskState = {
    isConnected: false,
    connect: () => {},
    walletAddresses: [],
};

export const MetamaskContext = React.createContext(defaultState);
const Provider = MetamaskContext.Provider;

export const MetamaskStore: React.FC = ({ children }) => {
    const [walletAddresses, setWalletAddresses] = useState<string[]>([]);

    const providerRef = useRef(
        new ethers.providers.Web3Provider(window.ethereum)
    );

    useEffect(() => {
        providerRef.current.listAccounts().then((a) => setWalletAddresses(a));
    }, []);

    const connect = () => {
        window.ethereum
            .request({
                method: "eth_requestAccounts",
            })
            .then((response: string[]) => setWalletAddresses(response))
            .catch(() => alert("Failed to connect to MetaMask account."));
    };

    const isConnected = !!walletAddresses.length;

    const state: MetamaskState = {
        isConnected,
        connect,
        walletAddresses,
    };

    return <Provider value={state}>{children}</Provider>;
};
