import styled from "styled-components";

export const ContractAddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 24px;
    padding: 12px;
    flex: 1;
    background-color: ${(props) => props.theme.background.highlightLight};
`;

export const ContractName = styled.h3`
    margin: 0;
    @media (min-width: 800px) {
        width: 240px;
    }
`;

export const ContractAddress = styled.div`
    padding-right: 12px;
    @media (max-width: 800px) {
        white-space: -pre-wrap;
        word-break: break-all;
    }
`;
export const AddressFooter = styled.div`
    display: flex;
    align-items: center;
    margin-top: 12px;
`;

export const CopyAddressButton = styled.button`
    all: unset;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: ${(props) => props.theme.form.primary};
`;

export const CopyAddressImage = styled.img`
    width: 1rem;
    height: 1rem;
    display: block;
`;
