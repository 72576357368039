import styled from "styled-components";

export const BalanceNumber = styled.span`
    font-size: 1.5rem;
    margin-bottom: 8px;
`;

export const BalanceSymbol = styled.span`
    color: #888888;
`;

export const CurrencyLogo = styled.img`
    height: 1rem;
    width: 1rem;
    display: inline-block;
    margin-right: 4px;
`;

export const BalanceCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.background.highlightLight};
    color: white;
    margin-top: 24px;
    padding: 12px;
    flex: 1;

    @media (min-width: 800px) {
        width: 240px;
    }
`;

export const BalanceCardFooter = styled.div`
    display: flex;
    align-items: center;
`;
