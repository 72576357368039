import { ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import abi from "../abi/abi.json";
import distributorAbi from "../abi/dividendDistributorAbi.json";

export const useWallet = (address: string) => {
    const providerRef = useRef(
        new ethers.providers.Web3Provider(window.ethereum)
    );
    const contractRef = useRef(
        new ethers.Contract(
            "0x2a2dA21ca96202387a80a1DD284829a1A9f5A678",
            abi,
            providerRef.current
        )
    );
    const busdContractRef = useRef(
        new ethers.Contract(
            "0xe9e7cea3dedca5984780bafc599bd69add087d56",
            abi,
            providerRef.current
        )
    );
    const distributorContractRef = useRef(
        new ethers.Contract(
            "0x3dA6E260bD679eCC4682f4988E786EE67b8f55Bf",
            distributorAbi,
            providerRef.current
        )
    );

    const [bnb, setBnb] = useState<string>("-");
    const [webReward, setWebReward] = useState<string>("-");
    const [busd, setBusd] = useState<string>("-");
    const [unpaidEarnings, setUnpaidEarnings] = useState<string>("-");
    const [totalEarnings, setTotalEarnings] = useState<string>("-");
    const [totalDistributed, setTotalDistributed] = useState<string>("-");

    useEffect(() => {
        if (address) {
            providerRef.current
                .getBalance(address)
                .then((balance) =>
                    setBnb(ethers.utils.formatEther(balance.toString()))
                );
        }
    }, [address]);

    useEffect(() => {
        if (address) {
            contractRef.current.balanceOf(address).then((balance: any) => {
                setWebReward(ethers.utils.formatUnits(balance, 9));
            });
        }
    }, [address]);

    useEffect(() => {
        if (address) {
            busdContractRef.current.balanceOf(address).then((balance: any) => {
                setBusd(ethers.utils.formatEther(balance));
            });
        }
    }, [address]);

    useEffect(() => {
        if (address) {
            distributorContractRef.current.functions
                .getUnpaidEarnings(address)
                .then((unpaidEarnings) => {
                    setUnpaidEarnings(
                        ethers.utils.formatEther(unpaidEarnings[0])
                    );
                });
        }
    }, [address]);

    useEffect(() => {
        if (address) {
            distributorContractRef.current.functions
                .shares(address)
                .then(([, , totalRealised]) => {
                    setTotalEarnings(ethers.utils.formatEther(totalRealised));
                });
        }
    }, [address]);

    useEffect(() => {
        if (address) {
            distributorContractRef.current.functions
                .totalDistributed()
                .then(([value]) => {
                    setTotalDistributed(ethers.utils.formatEther(value));
                });
        }
    }, [address]);

    return {
        bnb,
        webReward,
        busd,
        unpaidEarnings,
        totalEarnings,
        totalDistributed,
    };
};
