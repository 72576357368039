import React from "react";
import { useMetamask } from "../../hooks/useMetamask";
import { Button } from "../button/Button";
import { 
    HeaderContainer, 
    HeaderTitle, 
    HeaderContents, 
    WebRewardLogo, 
    WebRewardText } from "./Header.styles";
import webRewardLogo from "../../images/logo-noshadow-trimmy.png";

interface HeaderProps {
    isWeb3: boolean;
}

export const Header: React.FC<HeaderProps> = (props) => {
    const { isConnected, connect } = useMetamask();

    return (
        <HeaderContainer>
            <HeaderTitle>
                <a href="https://www.webreward.net/">
                    <HeaderContents>
                    <span>
                        <WebRewardLogo src={webRewardLogo}/> 
                    </span>
                    <WebRewardText>
                        <span>WebReward</span>
                    </WebRewardText>
                    </HeaderContents>
                </a>
            </HeaderTitle>
                {props.isWeb3 ? 
                    isConnected ? (
                        <Button disabled>Connected</Button>
                    ) : (
                        <Button onClick={() => connect()}>Connect to Metamask</Button>
                    ) : null}

        </HeaderContainer>
    );
};
